import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './sidebar';
import './css/Templates.css';

function TemplatesList() {
    const [templates, setTemplates] = useState([]);
    const apiBaseUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();

    useEffect(() => {
      fetch(`${apiBaseUrl}/api/templates`)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => setTemplates(data))
        .catch(error => {
          console.error('Error fetching templates:', error);
        });
    }, [apiBaseUrl]);

    const handleDelete = (templateId) => {
      fetch(`${apiBaseUrl}/api/templates/${templateId}`, {
        method: 'DELETE',
      })
      .then(response => {
        if (!response.ok) {
          return response.text().then(text => {
            throw new Error(`HTTP ${response.status}: ${text}`);
          });
        }
        return response.json();
      })
      .then(data => {
        console.log(data.message);
        // Remove the deleted template from state
        setTemplates(prevTemplates => prevTemplates.filter(t => t.id !== templateId));
      })
      .catch(error => {
        console.error('Error deleting template:', error);
      });
    };

    return (
      <div className='templates-page'>
        <Sidebar />
        <div className='template-content'>
          <h1>Templates</h1>
          <button onClick={() => navigate("/templates/new") }>
            Add Template
          </button>
          <div className='template-list'>
            <ul>
              {templates.map((template) => (
                <li key={template.id}>
                  {template.name} - <a href={template.link} target="_blank" rel="noopener noreferrer">{template.link}</a>
                  <button onClick={() => navigate(`/templates/${template.id}/edit`)}>
                    Edit
                  </button>
                  <button onClick={() => handleDelete(template.id)}>Delete</button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
}

export default TemplatesList;
