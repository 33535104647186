import React, { useState, useEffect } from 'react';
import { useParams, useNavigate} from 'react-router-dom';
import Sidebar from './sidebar';

function TemplateEdit() {
  const { templateId } = useParams(); // get the templateId from the URL
  
  const [templateName, setTemplateName] = useState('');
  const [backgroundImage, setBackgroundImage] =useState(null);
  const [club_logoImage, setClub_logoImage] =useState(null);
  const [position_fieldImage, setPosition_fieldImage] =useState(null);
  const [sponsor_logoImage, setSponsor_logoImage] =useState(null);
  const [backsideImage, setBacksideImage] =useState(null);
  const navigate = useNavigate();

  const apiBaseUrl = process.env.REACT_APP_API_URL;

  // Fetch the existing template data on mount to pre-fill fields
  useEffect(() => {
    fetch(`${apiBaseUrl}/api/templates`)
      .then(res => res.json())
      .then(data => {
        const currentTemplate = data.find(t => t.id === templateId);
        if (currentTemplate) {
          setTemplateName(currentTemplate.name);
          // If you have URLs for images, you can also show previews,
          // but for now, we just load the name.
        }
      })
      .catch(error => console.error('Error fetching template:', error));
  }, [apiBaseUrl, templateId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', templateName);

    if (backgroundImage) formData.append('backgroundImage', backgroundImage);
    if (club_logoImage) formData.append('club_logoImage', club_logoImage);
    if (position_fieldImage) formData.append('position_fieldImage', position_fieldImage);
    if (sponsor_logoImage) formData.append('sponsor_logoImage', sponsor_logoImage);
    if (backsideImage) formData.append('backsideImage', backsideImage);

    fetch(`${apiBaseUrl}/api/templates/${templateId}`, {
      method: 'PATCH',
      body: formData,
    })
    .then((response) => {
      if (!response.ok) {
        return response.text().then((text) => {
          throw new Error(`HTTP ${response.status} ${response.statusText}: ${text}`);
        });
      }
      return response.json();
    })
    .then((data) => {
      console.log('Template Updated:', data);
      navigate('/templates');
    })
    .catch((error) => {
      console.error('Error updating template:', error);
    });
  };

  // Handlers for file uploads
  const handleBackgroundUpload = (e) => setBackgroundImage(e.target.files[0]);
  const handleClub_logoUpload = (e) => setClub_logoImage(e.target.files[0]);
  const handlePosition_fieldUpload = (e) => setPosition_fieldImage(e.target.files[0]);
  const handleSponsor_logoUpload = (e) => setSponsor_logoImage(e.target.files[0]);
  const handleBacksideUpload = (e) => setBacksideImage(e.target.files[0]);

  return (
    <div className='templates-page'>
      <Sidebar />
      <div className='template-content'>
      <h1>Edit Template</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Template Name:
          <input
            type="text"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            required
          />
        </label>
        <label>
          Background.png:
          <input type="file" onChange={handleBackgroundUpload} />
        </label>
        <label>
          Club Logo:
          <input type="file" onChange={handleClub_logoUpload} />
        </label>
        <label>
          Position Feld:
          <input type="file" onChange={handlePosition_fieldUpload} />
        </label>
        <label>
          Sponsoren Logo:
          <input type="file" onChange={handleSponsor_logoUpload} />
        </label>
        <label>
          Backside:
          <input type="file" onChange={handleBacksideUpload} />
        </label>
        <button type="submit">Save Template</button>
      </form>
      </div>
    </div>
  );
}

export default TemplateEdit;
