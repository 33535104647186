import React, { useState, useEffect } from 'react';
import Sidebar from '../components/sidebar';
import "./css/Manual.css";

const Manual = () => {
    const [imagePreview, setImagePreview] = useState(null);
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [processing, setProcessing] = useState(false);

    const apiBaseUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        // Fetch templates using the same structure as TemplatesList.js
        fetch(`${apiBaseUrl}/api/templates`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => setTemplates(data))
            .catch(error => {
                console.error('Error fetching templates:', error);
            });
    }, [apiBaseUrl]);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);

        // Append selected template ID to formData
        formData.append('template_id', selectedTemplate);

        try {
            setProcessing(true);
            const response = await fetch(`${apiBaseUrl}/upload/manual`, {
                method: 'POST',
                body: formData
            });
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }

            // Start polling for processing status
            pollForProcessingStatus();
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const pollForProcessingStatus = async () => {
        const pollInterval = 5000;

        const checkProcessingStatus = async () => {
            try {
                const response = await fetch(`${apiBaseUrl}/check_processing_status`);
                const data = await response.json();

                if (data.status === 'completed') {
                    window.location.href = `${apiBaseUrl}/download_manual`;
                    setProcessing(false);
                } else {
                    setTimeout(checkProcessingStatus, pollInterval);
                }
            } catch (error) {
                console.error('Error while polling:', error);
                setProcessing(false);
            }
        };

        checkProcessingStatus();
    };

    return (
        <div className="manual-page">
            <Sidebar />
            <h1>Manual Page</h1>
            <div className="manual-content">
                <form id="manual-upload-form" onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="name-field">
                        <p>Name</p>
                        <input type="text" name="name" placeholder="Name" />
                    </div>
                    <div className="template-field">
                        <p>Template</p>
                        <select
                            name="template_id"
                            value={selectedTemplate}
                            onChange={(e) => setSelectedTemplate(e.target.value)}
                            required
                        >
                            <option value="" disabled>Select a template</option>
                            {templates.map((template) => (
                                <option key={template.id} value={template.id}>
                                    {template.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="surname-field">
                        <p>Nachname</p>
                        <input type="text" name="surname" placeholder="Surname" />
                    </div>
                    <div className="position-field">
                        <p>Position</p>
                        <select name="position">
                            <option value="" disabled>Select Position</option>
                            <option value="MITTELFELD">Mittelfeld</option>
                            <option value="ABWEHR">Abwehr</option>
                            <option value="ANGRIFF">Angriff</option>
                            <option value="TORWART">Torwart</option>
                            <option value="TRAINER">Trainer</option>
                        </select>
                    </div>
                    <div className='image-container'>
                        <div className='image-upload'>
                            <input type="file" name="image" accept="image/*" onChange={handleImageChange} />
                            {imagePreview ? (
                                <img src={imagePreview} alt="Preview" className="image-preview" />
                            ) : (
                                <div className="image-placeholder-box">Image Preview</div>
                            )}
                        </div>
                    </div>
                    <div className="manual-create-button">
                        <button type="submit" disabled={processing}>
                            {processing ? 'Processing...' : 'Create'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Manual;
